<template>
  <div class="container d-flex flex-column justify-center">
    <div class="d-flex justify-space-around flex-column flex-sm-row">
      <div style="width: 100%" class="d-flex">
        <v-btn icon> <v-icon @click="addDay(-1)"> mdi-chevron-left </v-icon> </v-btn>
        <date-picker
        :key="settlementDate"
          outlined
          dense
          style="width: 90%"
          :label="$vuetify.lang.t('$vuetify.dashboard.settlementDate')"
          :defaultNowOnNull="false"
          :show-hint="false"
          :show-icon="false"
          v-model="settlementDate"
          class="mx-2"
          @change="worksheets = null"
        />
        <v-btn icon> <v-icon @click="addDay(1)"> mdi-chevron-right </v-icon> </v-btn>
      </div>
      <v-select
        v-model="selectedReport"
        :items="reports"
        item-text="name"
        item-value="id"
        return-object
        outlined
        dense
        class="mx-2"
        style="min-width: 300px;"
        :label="$vuetify.lang.t('$vuetify.worksheet.chooseSettlement')"
        @change="worksheets = null"
      />
      <v-btn class="mx-auto" @click="fetchData" :loading="loading">
        {{ $vuetify.lang.t("$vuetify.base.submit") }}
      </v-btn>
    </div>

    <v-divider />

    <v-data-table
      v-if="worksheets"
      :loading="loading"
      :headers="computedHeaders"
      :items="worksheets"
    >
      <template v-slot:item.confirmed="{ item }">
        <v-icon v-if="item.confirmed" color="green"> mdi-check </v-icon>
      </template>
      <template v-slot:item.id="{ item }">
        <div>
          <a style="color: #1565c0" @click="viewWorksheet(item)">
            {{ item.id }} <span v-if="item.documentNumber">/ {{ item.documentNumber }} </span>
          </a>
        </div>
        <div style="white-space: nowrap; text-caption">
          
        </div>
      </template>
      <template v-slot:item.submittedTime="{ item }">
        {{ item.submittedTime | formatTime }}
      </template>
      <template v-slot:item.totalEntryNet="{ item }">
        {{ item.totalEntryNet | formatNumber }}
      </template>
      <template v-slot:item.processDirection="{ item }">
        <span v-if="item.processDirection == 'Input'">
          {{ $vuetify.lang.t("$vuetify.worksheet.in") }}
        </span>
        <span v-else-if="item.processDirection == 'Output'">
          {{ $vuetify.lang.t("$vuetify.worksheet.out") }}
        </span>
        <span v-else>
          {{ item.processDirection }}
        </span>
      </template>
    </v-data-table>

    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="showWorksheetDialog"
      style="z-index: 1000"
    >
      <v-card v-if="selectedWorksheet">
        <v-card-title class="justify-end">
          <div>
            <v-btn @click="showWorksheetDialog = null" icon>
              <v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-switch
              v-model="selectedWorksheet.confirmed"
              :label="`${this.$vuetify.lang.t('$vuetify.message.confirmed')}: ${
                selectedWorksheet.confirmed ? 'Yes' : 'No'
              }`"
              @change="updateConfirmation(selectedWorksheet)"
            />
          </div>
          <worksheet-card
            :worksheet="selectedWorksheet.id"
            :key="selectedWorksheet.id"
            :showEditButtons="false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");

export default {
  data: () => ({
    loading: false,
    reports: [],
    selectedReport: null,
    showWorksheetDialog: false,
    selectedWorksheetId: null,
    worksheets: [],
    settlementDate: new moment().format("YYYY-MM-DD"),
  }),
  components: { WorksheetCard },
  async created() {
    this.loadProcessTypes();
    this.reports = await this.fetchReportsBySupervisor();
    if (this.reports && this.reports.length > 0) {
      this.selectedReport = this.reports[0];
    }
    await this.fetchData();
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("processes", ["processTypes"]),
    headers() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.message.confirmed"),
          value: "confirmed",
          align: "start",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.label.id"),
          value: "id",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.label.submittedTime"),
          value: "submittedTime",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.label.inputOutput"),
          value: "processDirection",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.label.productName"),
          value: "productName",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.worksheet.location"),
          value: "locationName",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.base.netWeight"),
          value: "totalEntryNet",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.base.notes"),
          value: "notes",
          align: "start",
          sortable: true,
        },
      ];
    },
    computedHeaders() {
      if (this.selectedReport && this.selectedReport.processTypeId) {
        return this.headers;
      } else {
        return this.headers.filter((h) => h.value != "processDirection");
      }
    },
  },
  methods: {
    ...mapActions("processWorksheet", ["fetchWorksheetSettlement"]),
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    addDay(day){
      this.settlementDate = moment(this.settlementDate, "YYYY-MM-DD").add(day, 'days').format("YYYY-MM-DD")
    },
    async fetchReportsBySupervisor() {
      if (this.currentUser) {
        const params = {
          page: 0,
          size: 1000,
          supervisorId: this.currentUser.uId,
        };
        return await this.$axios
          .get("/supervisorWorksheetView", { params })
          .then((resp) => {
            return resp.data.content.map((r) => {
              const paramJson = JSON.parse(r.pageParams.parameters);
              const typeId = paramJson.typeId;
              const processTypeId = paramJson.processTypeId;
              return {
                id: r.id,
                paramId: r.pageParams.id,
                name: r.pageParams.name,
                typeId,
                processTypeId,
              };
            });
          });
      }
    },
    async fetchData() {
      if(!this.selectedReport){
        return [];
      }

      this.loading = true;
      const params = {
        page: 0,
        size: 1000,
        sort: "id",
        start: this.settlementDate,
        end: this.settlementDate,
        worksheetTypeId: this.selectedReport
          ? this.selectedReport.typeId
          : null,
        processTypeId: this.selectedReport
          ? this.selectedReport.processTypeId
          : null,
      };

      this.worksheets = await this.fetchWorksheetSettlement(params)
        .then(async (response) => {
          const worksheets = response.content;
          console.log("worksheets: ", worksheets);
          await this.syncWorksheetConfirmations(worksheets);
          return worksheets;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async syncWorksheetConfirmations(worksheets) {
      const confirmations = await this.fetchConfirmations();
      if (confirmations) {
        worksheets.forEach((w) => {
          const confirmation = confirmations.find((c) => c.worksheetId == w.id);
          if (confirmation) {
            w.confirmed = true;
            w.confirmationId = confirmation.id;
          }
        });
      }
    },
    async fetchConfirmations() {
      const params = {
        page: 0,
        size: 1000,
        supervisorId: this.currentUser.uId,
        settlementDate: this.settlementDate,
      };
      return await this.$axios
        .get("/worksheetSettlement", { params })
        .then((resp) => {
          console.log(resp.data.content);
          return resp.data.content;
        });
    },
    viewWorksheet(worksheet) {
      this.selectedWorksheet = worksheet;
      this.showWorksheetDialog = true;
    },
    updateConfirmation(worksheet) {
      if (worksheet.confirmed) {
        const data = {
          supervisorId: this.currentUser.uId,
          worksheetId: worksheet.id,
          settlementDate: this.settlementDate,
        };
        this.$axios.post("/worksheetSettlement", data).then((resp) => {
          worksheet.confirmationId = resp.data.id;
        });
      } else {
        this.$axios
          .delete("/worksheetSettlement/" + worksheet.confirmationId)
          .then((resp) => {
            worksheet.confirmationId = null;
            worksheet.confirmed = null;
          });
      }
    },
  },
};
</script>

<style>
</style>